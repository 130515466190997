import axios from "axios";
import React, { Component } from "react";
import AuctionList from "./AuctionList";

import Menu from "./Menu";
import { apiDomainForComponents } from "./config";


class Auctions extends Component {
    state = {
        // favorites: [],
        auctions: [],
        loader: false,
        text: '',
        url: apiDomainForComponents+"/api/auctions",
        urlText: apiDomainForComponents+"/api/message",
        user: localStorage.getItem('user-email'),
        urlfavorite: apiDomainForComponents+"/api/favorites-stars-list/"+localStorage.getItem('user-email'),

    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    getText = async () => {
        this.setState({loader: true});
      const text = await axios.get(this.state.urlText);
      this.setState({ text: text.data.text_content, loader: false});
    };


    // getFavorites = async () => {
    //     const favoriteGetted = await axios.get(this.state.urlfavorite);

    //     this.setState({ favorites: favoriteGetted.data,loader: false});
    //     console.log(favoriteGetted.data);
    // };

    componentDidMount() {
        this.getAuctions();
        // this.getFavorites();
        this.getText();
    }


    render() {

        return <div>
        <Menu/>
        <div className="page-content bg-white" style={{overflowX: "hidden"}}>
        <div className="row">
            <div className="col-lg-12">
                                   
                                        <img src="images/main-slider/pic1.jpg" style={{maxHeight: "80vh",minWidth: "100vw"}} alt=""/> 
                                    
                         
                    
            
        </div>
    </div>
</div>
                <AuctionList auctions={this.state.auctions}/>
            </div>
    }
}

export default Auctions;