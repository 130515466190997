import axios from "axios";
import React, { Component } from "react";
import MenuQuests from './MenuQuests';
import NewAuctionListForQuests from "./NewAuctionListForQuests";

import { apiDomainForComponents } from "./config";


class NewAuctionsForQuests extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        text: '',
        url: apiDomainForComponents+"/api/auctions",
        urlText: apiDomainForComponents+"/api/message",
    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    getText = async () => {
        this.setState({loader: true});
      const text = await axios.get(this.state.urlText);
      this.setState({ text: text.data.text_content, loader: false});
    };

    componentDidMount() {
        this.getAuctions();
        this.getText();
    }
    
    render() {

        return <div>
            <MenuQuests/>
            <div className="page-content bg-white" style={{overflowX: "hidden"}}>
			<div className="row">
            <div className="col-lg-12">
                                   
                                   <img src="images/main-slider/pic1.jpg" style={{maxHeight: "80vh",minWidth: "100vw"}} alt=""/> 
                               
                    
               
       
   </div>
			
		</div>
</div>
<NewAuctionListForQuests favorites={this.state.favorites} auctions={this.state.auctions}/>
                </div>
    }
}

export default NewAuctionsForQuests;