import React, { Component } from "react";
import Menu from "./Menu";



class Regulation extends Component {
    state = {
        loader: false,
    }

    render() {

        return <div>
      <Menu/>
            <div className="ui main container" style={{overflowX: "hidden"}}>
                <div>
                <p style={{marginTop:'4cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm',textAlign:'center'}}><strong>REGULAMIN KORZYSTANIA Z SERWISU LICYTACJE ZE SZWAJCARII</strong></p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm',textAlign:'center'}}><strong>z dnia 1.08.2023</strong></p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>&nbsp;</strong></p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>DEFINICJE:</strong></p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Serwis</strong>- strona internetowa <a href="http://www.licytacjezeszwajcarii.pl">www.licytacjezeszwajcarii.pl</a>, kt&oacute;rej nadrzędnym celem jest przekazywanie informacji i pośredniczenie w składaniu ofert w licytacjach.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Kupujący</strong>- osoba posiadająca indywidualny login i hasło, kt&oacute;ra uregulowała depozyt oraz jej tożsamość fizyczna lub prawna została zweryfikowana przez Serwis. Ilekroć Kupującym jest osoba fizyczna, wyraża ona pełne, nieograniczone prawo do otrzymywania informacji handlowych drogą telefoniczną i/lub mailową dotyczących ofert publikowanych w Serwisie. Dane os&oacute;b fizycznych i prawnych wskazane w rejestracji (otrzymanie loginu i hasła) są przetwarzane tylko i wyłącznie w celach realizacji transakcji. Dane wskazane przy rejestracji muszą być tożsame z danymi w procesie transakcji. Kupujący ma prawo do zwiany danych.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Dostawca</strong>- Osoba fizyczna lub prawna, wsp&oacute;łpracująca z Serwisem i dokonująca na jego zlecenie wszelkich czynności związanych z przebiegiem transakcji. Dostawca odpowiedzialny jest za przebieg i harmonogram Transportu oraz rozliczenia za Przedmiot</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Transakcja</strong>- proces rozpoczynający się od momentu złożenia oferty na wybrany Przedmiot i kończąca się na odbiorze oraz uregulowaniu wszystkich należności wynikających z transakcji przez Kupującego i Serwis i/lub Dostawcę</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Transport</strong> &ndash; usługa dostarczenia Przedmiotu wraz ze wszystkimi niezbędnymi dokumentami pozwalającymi na wywiezienie i przywiezienie Przedmiotu do miejsca docelowego, wskazanego przez Kupującego.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}><strong>Przedmiot</strong> &ndash; pojazdy mechaniczne, przedmioty motoryzacyjne</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>1. Właścicielem serwisu www.licytacjezeszwajcarii.pl jest Tomasz Krawczyk, prowadzący działalność gospodarczą na ternie Republiki Czeskiej. HAFEN TEILE TOMASZ KRAWCZYK, 17 Listopadu 388/17, 74301 Bilovec , CZ683496849</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>2. Użytkownikiem serwisu www.licytacjezeszwajcarii.pl &ndash; zwanym dalej Serwisem, może być każda osoba fizyczna lub prawna, kt&oacute;ra zarejestrowała się w panelu użytkownika (posiada sw&oacute;j unikatowy login i hasło), zapoznała się i zaakceptowała REGULAMIN korzystania z serwisu oraz zasady Polityki prywatności &ndash; zwana dalej Kupującym. Niniejszy Regulamin zostanie dostarczony drogą mailową na adres wskazany przy rejestracji. Warunkiem zakończenia procesu rejestracji jest akceptacja regulaminu w odpowiedzi zwrotnej, tylko i wyłącznie z maila, kt&oacute;ry został wskazany przy rejestracji.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>3. Kupujący wpłaca na rzecz serwisu depozyt ustalany indywidualnie. Depozyt jest nieoprocentowany i nie zobowiązuje Kupującego do zakupu. Stanowi zabezpieczenie serwisu na wypadek niewywiązania się Kupującego z warunk&oacute;w korzystania z serwisu. Depozyt zostanie Kupującemu zwr&oacute;cony po likwidacji konta użytkownika w Serwisie. Każdorazowo depozyt jest wpłacany na numer konta wskazany przez Serwis lub got&oacute;wką za potwierdzeniem wpłaty. Serwis zastrzega sobie prawo do zwiększenia lub zmniejszenia depozytu, na podstawie indywidualnych ustaleń z Kupującym.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>4. Z depozytu w pierwszej kolejności zostaną rozliczone ewentualne zobowiązania Kupującego wobec Serwisu.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>5. Wszystkie prezentowane przez Serwis informacje mają wyłącznie charakter informacyjny i nie stanowią oferty w rozumieniu przepis&oacute;w kodeksu cywilnego oraz innych przepis&oacute;w regulujących obr&oacute;t towarowy w Unii Europejskiej i poszczeg&oacute;lnych krajach członkowskich.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>6. Serwis jest portalem udostępniającym aukcje/licytacje, a Właściciel Serwisu nie ponosi odpowiedzialności za stan techniczny, wizualny a także wyposażenie samochod&oacute;w. Kupujący podejmuje samodzielne decyzje i bierze za nie pełna odpowiedzialność. Właściciel Serwisu nie ponosi r&oacute;wnież odpowiedzialności za wszelkie pomyłki w opisie aukcji/licytacji ze strony ubezpieczyciela/właściciela Przedmiotu.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>7. Celem serwisu www.licytacjezeszwajcarii.pl jest wsparcie zakupu Przedmiotu &nbsp;przez Kupującego.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>8. Przedmioty oznaczone są indywidualnym numerem referencyjnym.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>9. Kupujący za pomocą Serwisu (wpisanie oferty i jej zaakceptowanie) Dostawcy informację, kt&oacute;ry Przedmiot oznaczony indywidualnym numerem referencyjnym i za jaką cenę chce zakupić. Wskazanie ceny zakupu jest jednoznaczne z koniecznością zapłaty danej kwoty, w momencie wygrania licytacji. Proces rozliczeniowy opisany został w poniższych punktach.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>10. Dostawca weźmie udział w aukcji/licytacji danego Przedmiotu, oferując cenę podaną przez Kupującego.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>11. O wyniku aukcji Kupujący zostanie powiadomiony przez Dostawcę natychmiast po zakończeniu i ostatecznym rozstrzygnięciu aukcji.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>12. Po przyznaniu Przedmiotu wylicytowanego na rzecz Kupującego, Dostawca prześle drogą mailową lub telefoniczną informację o danych do opłaty za Przedmiot.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>13. Kupujący zobowiązuje się do dokonania opłaty za przyznany mu przedmiot w terminie 3 dni roboczych.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>14. W przypadku nieopłacenia i nieodebrania zakupionego Przedmiotu przez Kupującego w terminie oznaczonym przez Dostawcę, Kupujący wyraża zgodę na zapłacenie kar umownych w następujących wysokościach:&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>a. 1% wartości brutto zakupionego Przedmiotu, za każdy dzień zwłoki w płatnościach, licząc od daty w kt&oacute;rym upływa termin płatności oznaczony w fakturze pro-forma.&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>b. 30 CHF opłaty parkingowej za każdy dodatkowy dzień przetrzymywania Przedmiotu nieodebranego przez Kupującego po dniu, w kt&oacute;rym upłynął termin płatności faktury.&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>c. Za nieopłacenie i nieodebranie zakupionego Przedmiotu przez Kupującego w terminie 30 dni od daty wystawienia faktury pro-forma, umowa wiążąca Kupującego i Dostawcę zostaje rozwiązania bez dodatkowych wezwań, a Kupujący traci prawo do odbioru Przedmiotu i wyraża zgodę na obciążenie go karą w wysokości 2.000 CHF (słownie frank&oacute;w szwajcarskich: dwa tysiące) oraz 900 CHF (słownie frank&oacute;w szwajcarskich: dziewięćset) za 30 dniowe parkowanie &ndash; łącznie 2.900 CHF (słownie frank&oacute;w szwajcarskich: dwa tysiące dziewięćset).&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>d. W przypadku nieopłacenia i nieodebrania Przedmiotu przez Kupującego opr&oacute;cz kary umownej, zlikwidowane zostanie jego konto użytkownika co uniemożliwi takiemu Kupującemu dalszą aktywność w Serwisie.&nbsp;</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>e. Rozwiązanie umowy jest jednoznaczne z rozliczeniem wpłaconego przez niego Depozytu na poczet kary umownej oraz innych zobowiązań wynikających z warunk&oacute;w korzystania z Serwisu. Po rozliczeniu tych zobowiązań pozostała kwota Depozytu zostanie przekazana na rachunek bankowy Kupującego.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>15. Ustalenia pkt. 14 podpunkty a, b, c dotyczą Przedmiot&oacute;w wartości 0-15.000 CHF. Warunki zakupu, zapłaty i odbioru Przedmiot&oacute;w o wartości większej niż 15.000 CHF zostaną ustalone indywidualnie.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>16. Wydanie Przedmiotu ma miejsce pod wskazanym przez Kupującego adresem. Serwis oraz Dostawca zastrzega, że Transport Przedmiotu może odbywać się przez podmioty wsp&oacute;łpracujące, przez co nie posiadają one wiedzy ani uprawnień do udzielania jakichkolwiek informacji dotyczących transakcji pomiędzy Kupującym a Serwisem i Dostawcą.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>17. Każdorazowo obliczenie koszt&oacute;w opłat, prowizji i transportu dokonywane jest indywidualnie przez serwis i przedstawiane Kupującemu przed złożeniem oferty.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>18. Warunkiem do wydania Kupującemu Przedmiotu oraz dokument&oacute;w związanych z Przedmiotem jest spełnienie wszystkich zobowiązań finansowych przez Kupującego na rzecz Dostawcy. W wyjątkowych przypadkach Dostawca może wyrazić zgodę na uregulowanie należności przy odbiorze Przedmiotu. Brak uregulowania należności najp&oacute;źniej w dniu otrzymania pojazdu skutkuje odmową wydania dokument&oacute;w i/lub pojazdu oraz naliczeniem dodatkowych opłat za ponowny transport i/lub oddelegowanie transportu w inne miejsce.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>19. Wszystkie spory wynikające z realizacji postanowień powyższych warunk&oacute;w korzystania z serwisu, Dostawca i Kupujący zobowiązują się do rozstrzygania na drodze negocjacji.</p>
<p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'8.0pt',marginLeft:'0cm'}}>21. Warunkiem przystąpienia do licytacji i złożenia oferty jest pisemna akceptacja REGULAMINU drogą mailową na adres <a href="mailto:biuro@licytacjezeszwajcarii.pl">biuro@licytacjezeszwajcarii.pl</a> bądź akceptacja regulaminu na stronie licytacjezeszwajcarii.pl .</p>
            </div>
            </div>
        </div>
    }
}

export default Regulation;