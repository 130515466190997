import axios from "axios";
import React, { Component } from "react";
import { apiDomainForComponents } from "./config";


class OffersAdminListSingle extends Component {

    setWin = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/set-win/'+id);
        window.location.reload(false);

    }

    render() {
        const auctionsadmins = this.props.auctionsadmins;
        return (

            <div className="table-responsive-sm" style={{overflowX: "hidden"}}>
                <table className="table table-offers">
                    <thead>
                    <tr>
                        <th>Data</th>
                        <th>Tytuł</th>
                        <th>Użytkownik</th>
                        <th>Cena</th>
                        <th>Status</th>
                        <th>Akcja</th>
                    </tr>
                    </thead>

                    <tbody>

                    {auctionsadmins.map((auctionsadmins) => {
                        return (
                            <tr>

                                <td>{auctionsadmins.created_at.split(".")[0].replace("T"," ")}</td>
                                <td>{auctionsadmins.auction_title}</td>
                                <td>{auctionsadmins.user_email}</td>
                                <td>{auctionsadmins.price} CHF</td>
                                <td>{(auctionsadmins.is_win === 1) ? <span style={{color : "green"}}>Wygrana</span> : 'Nie oznaczona jako wygrana'}</td>
                                <td><button className="btn-primary btn btn-sm btn-block" onClick={() => this.setWin(auctionsadmins.id)}>Oznacz jako wygraną</button></td>

                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>
        );
    }
}

export default OffersAdminListSingle;