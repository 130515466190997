import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents } from "./config";



class AddText extends Component {

    constructor() {
        super();
        this.state = {
            text: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputText = (e) => {    this.setState({
        text: e.target.value,

    });  }

    createText = async (e) => {
        e.preventDefault();
        const res = await axios.post(apiDomainForComponents+'/api/set-text', {
            text: this.state.text,

        }).then(() => {
            window.alert('Poprawnie zmieniono tekst');
        })

    }

    render() {
    return (<>
        <Menu/>
        <section className="content-inner" style={{overflowX: "hidden"}}>
        <div className="container">
            <div className="row call-to-action-bx">
                <div className="col-xl-5 col-lg-6 me-auto">
                    <div className="section-head">
                        <h4 className="title text-white">Ustaw tekst</h4>
                    </div>
                    <form onSubmit={this.createText}>
                    <textarea className="btn btn-white me-3 mb-2" required type="text" name="text" placeholder="Tekst" value={this.state.text} onChange={this.handleInputText}/>
    
                    <button className="submit btn btn-outline-white effect-1  mb-2"><span>Zmień tekst</span></button>
                    </form>
                </div>
                <div className="col-lg-6">
                    <div className="media-box">
                        <img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
                        <img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    );
    }
}

export default AddText;