import axios from "axios";
import React, { Component } from "react";
import Loader from "./Loader";
import OffersAdminListSingle from "./OffersAdminListSingle";

import Menu from "./Menu";
import { apiDomainForComponents } from "./config";


class AdministrationSingle extends Component {
    state = {
        auctionsadmins: [],
        loader: false,
        url: apiDomainForComponents+"/api/administration/"+this.props.match.params.id,
        user: localStorage.getItem('user-email')
    }
    getAuctionsAdmin = async () => {
        this.setState({loader: true});
        const auctionsadmins = await axios.get(this.state.url);
        this.setState({ auctionsadmins: auctionsadmins.data, loader: false});
    };
    componentDidMount() {
        this.getAuctionsAdmin();
    }

    render() {

        return <div>
            <Menu/>
            <div className="ui main container">
                {
                    this.state.loader ? <Loader /> : ""
                }
                <OffersAdminListSingle auctionsadmins={this.state.auctionsadmins}/>
            </div>
        </div>
    }
}

export default AdministrationSingle;