import React, { Component } from "react";



class AuctionForQuests extends Component {

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at,images_content} = this.props.auctions;
        var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
         return (
            <>
            <a href={'/aukcja/'+id} className="col-xl-12 col-sm-6 col-md-6 m-b30">
            <div>
								<div className="car-list-box list-view">
            <div className="media-box">
            <img src={thumbnail_link.includes("base64") ? thumbnail_link : 'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} alt="" />
        </div>
        <div className="list-info">
            <h4 className="title mb-0">{title.slice(0, -14)}</h4>
            <div className="car-type">{insurance}</div>
            <span className="badge m-b30">{end_at} {is24 ? (
                   <span className="text-white"> Nowa </span>  ) : (
                        ''
                      )}</span>
        </div>
        </div>
        </div>
        </a>
        </>
        );
    }

}

export default AuctionForQuests;