import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents } from "./config";



class AddUser extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            passwordConfirm: '',
            email: '',
            name: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputName = (e) => {    this.setState({
        name: e.target.value,

    });  }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }

    handleInputPass = (e) => {    this.setState({
        password: e.target.value,
    }); }

    handleInputPassConfirm = (e) => {    this.setState({
        passwordConfirm: e.target.value,
    }); }

    createUser = async (e) => {
        e.preventDefault();
        if (this.state.password == this.state.passwordConfirm) {
        const res = await axios.post(apiDomainForComponents+'/api/register', {
            password: this.state.password,
            name: this.state.name,
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie dodano użytkownika');
        })
    }
    else {
        window.alert('Hasła nie są identyczne');
    }

    }

    render() {
        return (<>
            <Menu/>
            <section className="content-inner" style={{overflowX: "hidden"}}>
			<div className="container">
				<div className="row call-to-action-bx">
					<div className="col-xl-5 col-lg-6 me-auto">
						<div className="section-head">
							<h4 className="title text-white">Dodaj użytkownika</h4>
						</div>
                        <form onSubmit={this.createUser}>
                        <input className="btn btn-white me-3 mb-2" required type="text" name="name" placeholder="Nazwa" value={this.state.name} onChange={this.handleInputName}/>
                        <input className="btn btn-white me-3 mb-2" required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}/>
                        <input className="btn btn-white me-3 mb-2" required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass}/>
                        <input className="btn btn-white me-3 mb-2" required type="password" name="passwordConfirm" placeholder="Powtórz Hasło" value={this.state.passwordConfirm} onChange={this.handleInputPassConfirm}/>

                        <button className="submit btn btn-outline-white effect-1  mb-2"><span>Dodaj użytkownika</span></button>
                        </form>
                    </div>
					<div className="col-lg-6">
						<div className="media-box">
							<img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
							<img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
        </>
        );
    }
}

export default AddUser;