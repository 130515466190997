import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import { apiDomainForComponents } from "./config";



class PasswordReset extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
        }
    }

    handleInput = (e) => {    this.setState({
        email: e.target.value,
    });  }

    SendNewPasswordRequest = async (e) => {
        e.preventDefault();
            const res = await axios.post(apiDomainForComponents+'/api/password/forgot-password', {
                email: this.state.email
            }).then(() => {
                window.alert('Poprawnie wysłano link resetowania');
            })

    }

    render() {
        return (
            <section className="content-inner" style={{overflowX: "hidden"}}>
			<div className="container">
				<div className="row call-to-action-bx">
					<div className="col-xl-5 col-lg-6 me-auto">
                        <form onSubmit={this.SendNewPasswordRequest}>
                        <input required type="text" name="email" className="btn btn-white me-3 mb-2" placeholder="E-mail" value={this.state.email} onChange={this.handleInput}/>
                        <button className="submit btn btn-outline-white effect-1  mb-2"><span>Wyślij link resetujący</span></button>
                        </form>
                    </div>
					<div className="col-lg-6">
						<div className="media-box">
							<img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
							<img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
        );
    }
}

export default PasswordReset;