import axios from "axios";
import React, { Component } from "react";
import FavoritesList from "./FavoritesList";

import Menu from "./Menu";
import { apiDomainForComponents } from "./config";


class MyFavorites extends Component {
    state = {
        offers: [],
        loader: false,
        url: apiDomainForComponents+"/api/favorites-auctions/"+localStorage.getItem('user-email'),
        user: localStorage.getItem('user-email')
    }
    getOffers = async () => {
        this.setState({loader: true});
        const offers = await axios.get(this.state.url);
        this.setState({ offers: offers.data, loader: false});
    };
    componentDidMount() {
        this.getOffers();
    }

    render() {

        return <div>
      <Menu/>
      <section className="content-inner-2" style={{overflowX: "hidden"}}>
			<div className="container">
            <div className="col-xl-12 col-lg-12">
                <FavoritesList offers={this.state.offers}/>
                </div>
        </div>
        </section>
        </div>
}
}

export default MyFavorites;