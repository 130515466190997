import React, { Component } from "react";
import Favorite from "./Favorite";

class FavoritesList extends Component {

    render() {
        const offers = this.props.offers;

        return (

            <div className="table-responsive-sm" style={{overflowX: "hidden"}}>
                <table className="table table-offers">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Ubezpieczalnia</th>
                        <th>Aukcja</th>
                        <th>Koniec</th>
                        <th>Usuń z ulubionych</th>
                    </tr>
                    </thead>

                    <tbody>
                    {offers.map((offer) => {
                        return (
                            <Favorite offer={offer} key={offer.id}/>
                        )
                    })}


                    </tbody>
                </table>
            </div>
        );
    }
}

export default FavoritesList;