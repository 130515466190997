import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import { apiDomainForComponents } from "./config";



class PasswordChange extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            confirmedpassword: '',
        }
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        localStorage.setItem("token", urlParams.get('token'));
        localStorage.setItem("email", urlParams.get('email'));
    }


    handleInputPass = (e) => {    this.setState({
        password: e.target.value,

    });console.log(this.state.token);  }

    handleInputCPass = (e) => {    this.setState({
        confirmedpassword: e.target.value,
    }); }

        changePassword = async (e) => {
        e.preventDefault();

        const res = await axios.post(apiDomainForComponents+'/api/password/reset', {
            password: this.state.password,
            password_confirmation: this.state.confirmedpassword,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token")

        }).then(() => {
            window.alert('Poprawnie zmieniono hasło');
        })

    }

    render() {
        return (
            <section className="content-inner" style={{overflowX: "hidden"}}>
			<div className="container">
				<div className="row call-to-action-bx">
					<div className="col-xl-5 col-lg-6 me-auto">
                        <form onSubmit={this.changePassword}>
                        <input className="btn btn-white me-3 mb-2" required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass}/>
                        <input className="btn btn-white me-3 mb-2" required type="password" name="confirmedpassword" placeholder="Powtórz hasło" value={this.state.confirmedpassword} onChange={this.handleInputCPass}/>
                        <button className="submit btn btn-outline-white effect-1  mb-2"><span>Zmień hasło</span></button>
                        </form>
                    </div>
					<div className="col-lg-6">
						<div className="media-box">
							<img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
							<img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
        );
    }
}

export default PasswordChange;