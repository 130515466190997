import axios from "axios";
import React, { Component } from "react";
import Menu from "./Menu";
import { apiDomainForComponents } from "./config";

class Users extends Component {
    
    
    state = {
        users: [],
        url: apiDomainForComponents+"/api/users",
        search: '',
        user: localStorage.getItem('user-email')
      }


    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
     getUsers = async () => {
      const users = await axios.get(this.state.url);
      this.setState({ users: users.data});
    };
     componentDidMount() {
        this.getUsers();
    }


    render() {
      
      let filteredUsers = this.state.users.filter(
            (usersS) =>{
                return usersS.email.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        
       return ( 
        
        
          <div>
          <Menu/>
          <section className="content-inner-2" style={{overflowX: "hidden"}}>
          <div className="container">
                <div className="col-xl-12 col-lg-12">
                {filteredUsers.map((users) => {
                      return (
            
          
                              <h2> {users.email} </h2>
                          
                    )
                })}
                </div>
            </div>
            </section>
            </div>
            
            );
    }
}


export default Users;
