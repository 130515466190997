import axios from "axios";
import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { apiDomainForComponents, secondAdminEmail } from "./config";

class Auction extends Component {

    state = {
        user: localStorage.getItem('user-email'),
    }

    addFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    dellFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    dellAuction = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-auction/'+id);
        window.location.reload(false);

    }

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at, images_content} = this.props.auctions;
        //  const {auction_id} = this.props.favorites;
        //  const obj = this.props.favorites;
         var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        return (
            <>
            <a href={'/aukcja/'+id} className="col-xl-12 col-sm-6 col-md-6 m-b30">
            <div>
								<div className="car-list-box list-view">
            <div className="media-box">
            <img src={thumbnail_link.includes("base64") ? thumbnail_link : 'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} alt="" />
        </div>
        <div className="list-info">
            <h4 className="title mb-0">{title.slice(0, -14)}</h4>
            <div className="car-type">{insurance}</div>
            <span className="badge m-b30">{end_at} {is24 ? (
                   <span className="text-white"> Nowa </span>  ) : (
                        ''
                      )}</span>
                      {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        <span className="car-type m-l30 m-b30" onClick={(e) => {this.dellAuction(id);e.stopPropagation();e.preventDefault();}}>Usuń</span>
                       ) : (null)
                    }
                    
        </div>
        </div>
        </div>
        </a>
        </>
        );
    }

}

export default Auction;