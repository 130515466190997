import React, { Component } from "react";
import Auction from "./Auction";

class AuctionList extends Component {
    constructor() {
        super();
        this.state = {
            user: localStorage.getItem('user-email'),
            search: '',
            id: localStorage.getItem('id')
        }
    }

    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }

    goToAuction=()=>{
        const auction = document.getElementById(this.state.id); 
        window.scrollTo({
          top: auction.offsetTop,
          behavior:"smooth"
      });
      };

    render() {
        const auctions = this.props.auctions;
        // const favorites = this.props.favorites;
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.search) !== -1 || auction.title.toLowerCase().indexOf(this.state.search) !== -1 || auction.title.indexOf(this.state.search) !== -1 || auction.insurance.indexOf(this.state.search) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.search) !== -1 || auction.insurance.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        return (<>
            <div className="widget style-1">
            <div className="widget-title">
                <h4 className="title">Szukaj</h4>
            </div>
            <div className="search-bx">
                    <div className="input-group">
                        <input className="form-control" value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj" type="text" />
                    </div>
            </div>
            </div>
            <section className="content-inner-2" style={{overflowX: "hidden"}}>
			<div className="container">
                  <div className="row lightgallery">
							
                                {filteredAuctions.map((auctions) => {
                      return (
                        <Auction auctions={auctions} key={auctions.id} id={auctions.id} />

                          )
                      })}
                      </div>
                      </div>
                      </section>
                </>
        );
    }
}

export default AuctionList;