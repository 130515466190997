import axios from "axios";
import React, { Component } from "react";

import { apiDomainForComponents } from "./config";

class MenuQuests extends Component {

  state = {
    auctions: [],
    loader: false,
    url: apiDomainForComponents+"/api/auctions",
    user: localStorage.getItem('user-email'),

}

  getAuctions = async () => {
    this.setState({loader: true});
  const auctions = await axios.get(this.state.url);
  this.setState({ auctions: auctions.data, loader: false});
  };

  componentDidMount() {
    this.getAuctions();
}

    render() {
      var arrayOfTrue = [];
        this.state.auctions.forEach((e) => {
          var date1 = new Date(e.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        if (is24) { arrayOfTrue.push(true) } 
        })
        const count = arrayOfTrue.length;
        const countAll = this.state.auctions.length;
        return (
            <div className="page-wraper">
	<header className="site-header mo-left header style-1">
		<div className="main-bar-wraper navbar-expand-lg">
			<div className="main-bar clearfix ">
				<div className="container clearfix">
					<div className="logo-header mostion logo-dark">
						<a href="/"><img src={'https://licytacjezeszwajcarii.pl/logo.png'} alt="logo" /></a>
					</div>
					<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div className="extra-nav">
						<div className="extra-cell">
							<a href="tel:793700760" className="btn btn-primary light phone-no shadow-none effect-1" style={{color: "#808080", backgroundColor: "#fff", border: "1px #808080 solid"}}><span><i className="fas fa-phone-volume shake"></i>+48 793 700 760</span></a>
						</div>
                				{/* <a className="btn btn-primary light m-1 phone-no" style={{color: "#808080", backgroundColor: "#fff", border: "1px #808080 solid"}} href={'https://www.tiktok.com/@licytacjezeszwajcarii?_t=8kkbzB29DZw&_r=1'}><span><i className="fab fa-facebook-f shake"></i></span></a> */}
								<a className="btn btn-primary light phone-no" style={{color: "#808080", backgroundColor: "#fff", border: "1px #808080 solid"}} href={'https://wa.me/48793700760'}><span><i className="fab fa-whatsapp shake"></i></span></a>
								{/* <a className="btn btn-primary light m-1 phone-no" style={{color: "#808080", backgroundColor: "#fff", border: "1px #808080 solid"}} href={'https://www.tiktok.com/@licytacjezeszwajcarii?_t=8kkbzB29DZw&_r=1'}><span><i className="fab fa-tiktok shake"></i></span></a>
								<a className="btn btn-primary light phone-no" style={{color: "#808080", backgroundColor: "#fff", border: "1px #808080 solid"}} href={'https://www.instagram.com/licytacjezeszwajcarii.pl?igsh=MWJzbXRpYTV0cHp6Ng%3D%3D&utm_source=qr'}><span><i className="fab fa-instagram shake"></i></span></a> */}
					</div>
					<div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
						<div className="logo-header">
							<a href="/"><img src={'https://licytacjezeszwajcarii.pl/logo.png'} alt="logo" /></a>
						</div>
						<ul className="nav navbar-nav navbar navbar-left">	
							<li><a href="/">LISTA AUKCJI<span className="badge badge-secondary"> {countAll} </span></a>
							</li>
              <li><a href="/nowe-aukcje">NOWE AUKCJE<span className="badge badge-success"> {count} </span></a>
							</li>
              <li><a href="/zaloguj">ZALOGUJ</a>
							</li>
						</ul>
            <a href="tel:793700760" className="btn btn-primary w-100 d-md-none"><span><i className="fas fa-phone-volume shake"></i>+48 793 700 760</span></a>
						<div className="dlab-social-icon">
              <ul>
								{/* <li><a className="fab fa-facebook-f" href={'https://www.tiktok.com/@licytacjezeszwajcarii?_t=8kkbzB29DZw&_r=1'}></a></li> */}
								<li><a className="fab fa-whatsapp" href={'https://wa.me/48793700760'}></a></li>
								{/* <li><a className="fab fa-tiktok" href={'https://www.tiktok.com/@licytacjezeszwajcarii?_t=8kkbzB29DZw&_r=1'}></a></li>
								<li><a className="fab fa-instagram" href={'https://www.instagram.com/licytacjezeszwajcarii.pl?igsh=MWJzbXRpYTV0cHp6Ng%3D%3D&utm_source=qr'}></a></li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
  </div> )
    }
}

export default MenuQuests;