import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents } from "./config";



class BlockUser extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            user: localStorage.getItem('user-email')
        }
    }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }


    blockUser = async (e) => {
        e.preventDefault();

        const res = await axios.post(apiDomainForComponents+'/api/block', {
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie usunięto użytkownika');
        })

    }

    render() {
return (<>
    <Menu/>
    <section className="content-inner" style={{overflowX: "hidden"}}>
    <div className="container">
        <div className="row call-to-action-bx">
            <div className="col-xl-5 col-lg-6 me-auto">
                <div className="section-head">
                    <h4 className="title text-white">Usuń użytkownika</h4>
                </div>
                <form onSubmit={this.blockUser}>
                <input className="btn btn-white me-3 mb-2" required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}/>

                <button className="submit btn btn-outline-white effect-1  mb-2"><span>Usuń użytkownika</span></button>
                </form>
            </div>
            <div className="col-lg-6">
                <div className="media-box">
                    <img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
                    <img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
</>
);
    }
}

export default BlockUser;