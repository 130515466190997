import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';
import AuthService from "./../services/AuthService";

import { contactEmail } from "./config";



class Login extends Component {
    state = {username: '', password: ''}
    async handleFormSubmit(event) {
        event.preventDefault();
        const postData = {username: this.state.username, password: this.state.password};
        console.log(postData.username);
        localStorage.setItem('user-email', postData.username);
        localStorage.setItem('footer', 'true');
        const response = await AuthService.doUserLogin(postData);
        if (response) {
            AuthService.handleLoginSuccess(response);
            //this.props.history.push('/lista-aukcji');
            window.location.replace("/lista-aukcji");
        }
    }
    render() {
        const {username,password} = this.state;
        return (
            <section className="content-inner" style={{overflowX: "hidden"}}>
			<div className="container">
				<div className="row call-to-action-bx">
					<div className="col-xl-5 col-lg-6 me-auto">
						<div className="section-head">
							<h4 className="title text-white">Aby się zarejestrować napisz na: <a href={'mailto:'+contactEmail}>{contactEmail}</a></h4>
						</div>
                        <form onSubmit={event => this.handleFormSubmit(event)}>
                        <input type="text" name="email" placeholder="E-mail" className="btn btn-white me-3 mb-2" value={username} onChange={event => this.setState({username: event.target.value})}/>
                        <input type="password" name="password" placeholder="Hasło" className="btn btn-white me-3 mb-2" value={password} onChange={event => this.setState({password: event.target.value})}/>

                        <button className="submit btn btn-outline-white effect-1  mb-2"><span>Zaloguj</span></button>
                        <a className="btn  mb-2" href={'/przypomnij-haslo'}><span>Przypomnij hasło</span></a>
                        </form>
                    </div>
					<div className="col-lg-6">
						<div className="media-box">
							<img src="https://licytacjezeszwajcarii.pl/images/about/pic5.jpg" className="main-img" alt="" />
							<img src="https://licytacjezeszwajcarii.pl/images/pattern/pattern7.png" className="pt-img move-1" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
        );
    }
}

export default Login;