import React, { Component } from "react";
import NewAuctionForQuests from "./NewAuctionForQuests";

class NewAuctionListForQuests extends Component {
    constructor() {
        super();
        this.state = {
            search: ''
        }
    }

    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
    render() {
        const auctions = this.props.auctions;
        var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.search) !== -1 || auction.title.toLowerCase().indexOf(this.state.search) !== -1 || auction.title.indexOf(this.state.search) !== -1 || auction.insurance.indexOf(this.state.search) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.search) !== -1 || auction.insurance.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        return (<>
            <div className="widget style-1">
            <div className="widget-title">
                <h4 className="title">Szukaj</h4>
            </div>
            <div className="search-bx">
                    <div className="input-group">
                        <input className="form-control" value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj" type="text" />
                    </div>
            </div>
            </div>
            <section className="content-inner-2" style={{overflowX: "hidden"}}>
			<div className="container">
            <div className="col-xl-12 col-lg-12">
                  <div className="row lightgallery">
							
                                {filteredAuctions.map((auctions) => {
                      return (
                        <NewAuctionForQuests auctions={auctions} key={auctions.id}/>

                          )
                      })}
                      </div>
                      </div>
                      </div>
                      </section>
                </>
        );
    }
}

export default NewAuctionListForQuests;