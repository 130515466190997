import axios from "axios";
import React, { Component } from "react";
import { apiDomainForComponents } from "./config";



class Favorite extends Component {

    deleteFromFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }
    render() {
        const { thumbnail_link,insurance,title,id,end_at,images_content } = this.props.offer;
        return (
            <tr>
                <td style={{textAlign: "center"}}><a href={'/aukcja/'+id}><img src={thumbnail_link.includes("base64") ? thumbnail_link : 'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} style={{width: "200px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td><a href={'/aukcja/'+id}>{title.slice(0, -14)}</a></td>
                <td>{end_at}</td>
                <td><button className="btn-primary btn btn-sm btn-block" onClick={() => this.deleteFromFavorite(id)}>Usuń z ulubionych</button></td>

            </tr>
        );
    }

}

export default Favorite;