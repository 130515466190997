import axios from "axios";
import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { apiDomainForComponents, secondAdminEmail } from "./config";



class AuctionDetails extends Component {

    constructor() {
        super();
        this.state = {
            favorites: [],
            price: '',
            user: localStorage.getItem('user-email'),
            urlfavorite: apiDomainForComponents+"/api/favorites-stars-list/"+localStorage.getItem('user-email'),
        }
    }


    handleInput = (e) => {    this.setState({
        price: e.target.value,
    });  }

    saveOffer = async (e) => {
        e.preventDefault();
        let agree = window.confirm("Potwierdź złożenie oferty w wysokości "+ this.state.price + " CHF?");
        if (agree) {
            const res = await axios.post(apiDomainForComponents+'/api/add-offer', {
                price: this.state.price,
                auctionid: this.props.auction.id,
                useremail: localStorage.getItem('user-email'),
                title: this.props.titleSliced,
                insurance: this.props.auction.insurance,
            }).then(() => {
                window.alert('Poprawnie zalicytowano');
                window.location.reload(false);
            })
        }
        else {
            return false;
        }
        }

        addFavorite = async (id) => {
            const res = await axios.get(apiDomainForComponents+'/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
            window.location.reload(false);
    
        }
    
        dellFavorite = async (id) => {
            const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
            window.location.reload(false);
    
        }

        getFavorites = async () => {
            const favoriteGetted = await axios.get(this.state.urlfavorite);
    
            this.setState({ favorites: favoriteGetted.data,loader: false});
            console.log(favoriteGetted.data);
        };

        setAuctionId = async () => {
            localStorage.setItem('id', this.props.auction.id);
        };
    
        componentDidMount() {
            this.getFavorites();
        }

        componentDidUpdate() {
            this.setAuctionId();
        }



    render() {
        const {id, insurance,end_at, description_table} = this.props.auction;
        const imgArray = this.props.images.toString().split(',').map((image) => {
            return image
            });
        const titleSliced = this.props.titleSliced;
        const auctionsadmins = this.props.auctionsadmins;
        return (
            <>
            <section className="content-inner-2" style={{overflowX: "hidden"}}>
			<div className="container">
				<div className="row">
					<div className=" col-xl-8 col-lg-7 col-md-6 m-b0 m-md-b50">
						{/* <div className="product-gallery on-show-slider lightgallery m-b40"> */}
                            {/* <Carousel showThumbs={false} showIndicators={false} swipeable={true} emulateTouch={true}> */}
                            {imgArray.map((image) => {
                                    return (
                                        <div>
										{/* <div className="dlab-thum-bx"> */}
											<img className="img-fluid" src={'https://api.licytacjezeszwajcarii.pl/storage/'+image} alt={image} key={image} />
										{/* </div> */}
									</div>
                                        )
                                    })}
            {/* </Carousel> */}
							
							{/* </div> */}
						<div className="m-b50">
							<div className="dlab-post-title">
								<h3 className="post-title"><a href="#">{titleSliced} {insurance}</a></h3>
                                <h1 className="title mb-0">{(this.state.favorites.includes(id)) ? (
                    <span className="m-b30" style={{textAlign: "center",cursor: "pointer"}} onClick={() => {this.dellFavorite(id)}}><i className="far fa-star" style={{color: "red", zIndex: "1050"}}></i></span>
                    ) :

                    ( <span className="m-b30" style={{textAlign: "center",cursor: "pointer"}} onClick={() => {this.addFavorite(id)}}><i className="far fa-star" style={{zIndex: "1050"}}></i></span> ) }</h1>
								<h6 className="sub-title">Data zakończenia: {end_at}</h6>
                                <div className="m-b10" dangerouslySetInnerHTML={{__html: description_table}}></div>
							</div>
						
	</div>
    </div>
                                {(document.referrer == (window.location.protocol + '//' + window.location.host + '/moje-oferty')) ? (<div></div>) : ( 
					<div className="col-xl-4  col-lg-5 col-md-6">
						<div className="sticky-top nav-tabs-top">
							<div className="car-dl-info icon-bx-wraper style-1 m-b50">
                            <form onSubmit={this.saveOffer}>
								<div className="form-group m-b20">
                                <label className="font-weight-600 m-b10">Złóż ofertę</label>
                                    
                            <input className="form-control sm" type="number" name="price" value={this.state.price} onChange={this.handleInput} placeholder="Cena w CHF"/>
                            <input type="hidden" name="auctionid" value={id}/>
                            <input type="hidden" name="insurance" value={insurance}/>
                            <input type="hidden" name="title" value={titleSliced}/>
                            <input type="hidden" name="useremail" value={localStorage.getItem('user-email')}/>
                        <div className="clearfix">
										<button className="btn-primary btn btn-block" style={{ marginTop: "20px"}}>Zalicytuj</button>
									</div>
                                    
                    
                    </div>
                    </form>
							</div>
                            
						</div>
                        
					</div>
                    )
                }
				
        {((this.state.user === "hafenteile@gmail.com") || (this.state.user === secondAdminEmail)) ? (
              <div className="table-responsive-sm">
              <table className="table table-offers">
                                        {auctionsadmins.map((auctionsadmins) => { return (
                    
                    <tr>                               <td>{auctionsadmins.created_at.split(".")[0].replace("T"," ")}</td>
                                                    <td>{auctionsadmins.auction_title}</td>
                                                    <td>{auctionsadmins.user_email}</td>
                                                    <td>{auctionsadmins.price} CHF</td>
                                                    </tr>
                                            )
                                        })}
                                        </table>
                                        </div>
                    ) : (null)
                                        }
            </div>
                </div>
		</section>
            </>
        );
    }

}

export default AuctionDetails;