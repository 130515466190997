import React, { Component } from "react";
// import axios from "axios";
import { } from 'react-router-dom';

import axios from "axios";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import FileBase64 from 'react-file-base64';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import Menu from "./Menu";
import { apiDomainForComponents } from "./config";



class AddAuction extends Component {

    constructor() {
        super();
        this.state = {
            title: '',
            insurance: '',
            endAt: '',
            images_content: ',',
            description_table: '',
            thumbnail_link: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputTitle = (e) => {    this.setState({
        title: e.target.value,

    });  }

    handleInputInsurance = (e) => {    this.setState({
        insurance: e.target.value,

    });  }

    handleInputEndAt = (date) => {    this.setState({
        endAt: date,
        

    }); }

    handleHtml = (html) => {    this.setState({
        description_table: html,

    });  }

    getFiles(base64){
        this.setState({ thumbnail_link: base64 })
      }

    createAuction = async (e) => {
        e.preventDefault();
        const res = await axios.post(apiDomainForComponents+'/api/add-auction', {
            title: this.state.title,
            insurance: this.state.insurance,
            end_at: this.state.endAt,
            images_content: this.state.images_content,
            description_table: this.state.description_table,
            thumbnail_link: this.state.thumbnail_link.base64


        }).then(() => {
            window.alert('Poprawnie dodano aukcję');
        })

    }

    render() {
        return <div>
        <Menu/>
        <section className="content-inner" style={{overflowX: "hidden"}}>
            <div className="container">
                <div className="row">
                        <h2>Dodaj aukcję</h2>
                <form onSubmit={this.createAuction}>
                                <input required type="text" className="form-control mb-2" name="title" placeholder="Tytuł" value={this.state.title} onChange={this.handleInputTitle}/>
                                <input required type="text" className="form-control mb-2" name="insurance" placeholder="Ubezpieczalnia" value={this.state.insurance} onChange={this.handleInputInsurance}/>
                            <p>Data zakończenia aukcji</p>                       
                                <Datetime
          value={this.state.endAt}
          name="end_at"
          onChange={this.handleInputEndAt}
          timeFormat={true}
          inputProps={{ placeholder: "Data końca aukcji" }}/>
                        
                        
                            <p>Miniaturka</p>
                            
                        <FileBase64 multiple={ false } onDone={ this.getFiles.bind(this) }/>
                        
                        <EditorToolbar />
                        <ReactQuill
                            theme="snow"
                            value={this.state.description_table}
                            onChange={this.handleHtml}
                            placeholder={"Opis aukcji i zdjęcia..."}
                            modules={modules}
                            formats={formats}
                        />
                        <button className="btn-primary btn btn-block m-5" >Dodaj aukcję</button>
                </form>
                </div>
                </div>
        </section>
        </div>

    }
}

export default AddAuction;


